
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { LocationData, FormData, PlantTypeList } from '@/types/rareTrees'
import MapLocation from '@/components/mapLocation/Index.vue'
import { DicInfo, DicList, FileInfo } from '@/types/common'
import { initWangEdit } from '@/utils/wangEdit'
import UploadFile from '@/components/uploadFile/Index.vue'

@Component({
  name: 'RareTreesAdd',
  components: { MapLocation, UploadFile },
  filters: {
    imgListFormat (list: FileInfo[]) {
      const imgList = list.map(item => {
        return item.filePrefix + item.fileUrl
      })
      return imgList || []
    }
  }
})
export default class extends Vue {
  private formData: FormData = {
    projectId: '',
    // 苗木类别
    seedlingType: '',
    // 苗木名称
    plantName: '',
    // 栽种日期
    plantDate: '',
    // 树龄
    treeAge: '',
    // 规格
    standard: '',
    // 苗木编号（古树特有，必填）
    seedlingCode: '',
    // 保护等级（古树特有，必填）
    protectionLevel: '',
    // 责任人（古树特有，选填）
    responsibleUser: '',
    // 责任单位（古树特有，选填）
    responsibleUnit: '',
    // 苗木位置信息（古树一个点，普苗木有多个点）
    locationList: [],
    // 苗木介绍
    seedlingIntroduce: '',
    fileList: []
  }

  private rulesForm = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'change' }
    ],
    seedlingType: [
      { required: true, message: '请选择苗木类别', trigger: 'change' }
    ],
    plantName: [
      { required: true, message: '请输入苗木名称', trigger: 'change' }
    ],
    seedlingCode: [
      { required: true, message: '请输入苗木编号', trigger: 'change' }
    ],
    plantDate: [
      { required: true, message: '请选择栽种日期', trigger: 'change' }
    ],
    protectionLevel: [
      { required: true, message: '请先选择保护等级', trigger: 'change' }
    ],
    locationList: [
      { required: true, message: '请选择坐标位置', trigger: 'change' }
    ],
    fileList: [
      { required: true, message: '请选择苗木图片', trigger: 'change' }
    ],
    seedlingIntroduce: [
      { required: true, message: '请输入苗木介绍', trigger: 'change' }
    ]
  }

  // 苗木类别
  private plantTypeList: PlantTypeList | null = null
  // 等级
  private protectionLevelList: Array<DicInfo> = []

  private activeName = '0'
  // 富文本
  private seedlingIntroduce: any = null
  private locationList: LocationData[] | null= []

  private submitDisabled = false

  get normalProjectList () {
    return this.$store.state.normalProjectList
  }

  get seedlingId () {
    return this.$route.params.seedlingId
  }

  created () {
    this.seedlingId && this.getDetail()
    this.getPlantType()
    this.getProtectionLevel()
    this.init()
  }

  destroyed () {
    // 销毁编辑器
    this.seedlingIntroduce.destroy()
    this.seedlingIntroduce = null
  }

  // // 苗木名称输入建议
  // getSeedlingSuggest (queryString: string, callback: Function) {
  //   this.$axios.get(this.$apis.seedling.selectListByPlantName, {
  //     plantName: queryString
  //   }).then((res) => {
  //     callback(res || [])
  //   })
  // }

  // // 选中后带出其他信息
  // getCurrentInformation (item: FormData) {
  //   this.formData.seedlingType = item.seedlingType
  //   this.formData.plantDate = item.plantDate
  //   this.formData.treeAge = item.treeAge
  //   this.formData.standard = item.standard
  //   this.formData.protectionLevel = item.protectionLevel
  //   this.formData.responsibleUnit = item.responsibleUnit
  //   this.formData.responsibleUser = item.responsibleUser
  //   this.formData.fileList = item.fileList
  //   this.seedlingIntroduce.txt.html(item.seedlingIntroduce || '')
  // }

  // // 清空数据
  // clearPlatName () {
  //   this.formData.seedlingType = ''
  //   this.formData.plantDate = ''
  //   this.formData.treeAge = ''
  //   this.formData.standard = ''
  //   this.formData.protectionLevel = ''
  //   this.formData.responsibleUnit = ''
  //   this.formData.responsibleUser = ''
  //   this.formData.fileList = []
  //   this.seedlingIntroduce.txt.html('')
  // }

  // 查询苗木类别列表
  getPlantType () {
    this.$axios.get(this.$apis.common.selectDicByList, { dicType: 'seedlingType' }).then(res => {
      this.plantTypeList = res.seedlingType || []
    })
  }

  // 获取保护等级列表
  getProtectionLevel () {
    this.$axios.get<DicList>(this.$apis.common.selectDicByList, {
      dicType: 'protectionLevel'
    }).then((res) => {
      this.protectionLevelList = res.protectionLevel || []
    })
  }

  getDetail () {
    this.$axios.get(this.$apis.seedling.selectYhSeedlingBySeedlingId, {
      seedlingId: this.seedlingId
    }).then((res) => {
      if (res) {
        this.formData = res
        this.locationList = res.locationList || []
        this.seedlingIntroduce.txt.html(res.seedlingIntroduce || '')
      }
    })
  }

  // 富文本初始化
  init () {
    this.$nextTick(() => {
      this.seedlingIntroduce = initWangEdit('#seedlingIntroduce', { placeholder: '请输入植物介绍' })
      this.seedlingIntroduce.create()
      // 动态监听富文本改变
      this.seedlingIntroduce.config.onchange = (html: string) => {
        this.formData.seedlingIntroduce = html
        if (html) {
          ;(this.$refs.formData as ElForm).clearValidate()
        }
      }
    })
  }

  imgRemove (index: number) {
    this.formData.fileList.splice(index, 1)
  }

  // 文件上传成功返回
  onSuccess (file: FileInfo) {
    this.formData.fileList.push(file)
    this.$nextTick(() => {
      (this.$refs.formData as ElForm).clearValidate()
    })
  }

  // 经纬度
  locationChange (arr: LocationData[]) {
    arr.forEach((item: any) => {
      delete item.treeWidth
      delete item.fileList
      delete item.growthTrend
    })
    this.formData.locationList = arr
  }

  onSubmit () {
    ;(this.$refs.formData as ElForm).validate((valid) => {
      if (valid) {
        this.submitDisabled = true
        const url = this.seedlingId ? this.$apis.seedling.updateYhSeedling : this.$apis.seedling.insertYhSeedling
        this.$axios.post(url, {
          isFamous: 1,
          ...this.formData
        }).then(() => {
          this.$message.success('保存成功')
          this.$router.back()
        }).finally(() => {
          this.submitDisabled = false
        })
      }
    })
  }
}
